import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { SinglePlanWidgetRole } from '../../constants/elements';
import { getParentWidget } from '../../utils/widget';
import { openChangeRibbonTextPanel } from '../Plan/editor.controller';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;
  builder.configureConnectedComponents(SinglePlanWidgetRole.RibbonText, (ribbonTextBuilder) => {
    ribbonTextBuilder.set({ displayName: t('blocks.ribbon-design.text') });
    ribbonTextBuilder
      .behavior()
      .set({ closed: { hideFromHierarchy: false, selectable: true }, dataEditOptions: 'TEXT_STYLE_ONLY' });
    ribbonTextBuilder.gfpp().set('mainAction2', {
      label: t('blocks.ribbon.edit-text'),
      onClick: async (e) =>
        openChangeRibbonTextPanel({
          editorSDK,
          componentRef: (await getParentWidget({ editorSDK, componentRef: e.detail.componentRef }))!,
          flowAPI,
        }),
    });
  });
};
