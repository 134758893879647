import { EditorSDK, TransactionError } from '@wix/platform-editor-sdk';
import { FlowAPI } from '@wix/yoshi-flow-editor';

const TOKEN = '';

export async function setPermissionsPage(editorSDK: EditorSDK, flowAPI: FlowAPI, appDefinitionId: string) {
  const packagePickerPageId = await findPackagePickerPageId(editorSDK, appDefinitionId);
  if (packagePickerPageId) {
    const noPermissionsPageId = await editorSDK.document.siteMembers.getCustomNoPermissionsPageId(TOKEN);
    if (noPermissionsPageId !== packagePickerPageId) {
      flowAPI.fedops.interactionStarted('es_set_permissions_page_tx');
      await runAndWaitForApprovalWithRetry(editorSDK, () =>
        editorSDK.document.siteMembers.setCustomNoPermissionsPageId(TOKEN, { pageId: packagePickerPageId }),
      );
      flowAPI.fedops.interactionEnded('es_set_permissions_page_tx');
    }
  }
}

async function findPackagePickerPageId(editorSDK: EditorSDK, appDefinitionId: string) {
  const pages = await editorSDK.document.pages.data.getAll(TOKEN);
  const page = pages?.find(
    (candidate) =>
      candidate.appDefinitionId === appDefinitionId && candidate.tpaPageId === 'membership_plan_picker_tpa',
  );
  return page?.id;
}

async function runAndWaitForApprovalWithRetry(editorSDK: EditorSDK, action: () => void) {
  try {
    await editorSDK.document.transactions.runAndWaitForApproval(TOKEN, action);
  } catch (e) {
    if (await editorSDK.document.transactions.isConflictError(TOKEN, e as TransactionError)) {
      await editorSDK.document.transactions.runAndWaitForApproval(TOKEN, action);
    } else {
      throw e;
    }
  }
}
